/* Cards.css */

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.cards__information1,
.cards__information2 {
  flex: 0.5;
  background-color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
   opacity: 0; /* Start with opacity 0 to hide the elements */
  animation-duration: 1s; /* Animation duration */
  animation-timing-function: ease-in-out; /* Animation timing function */
  animation-fill-mode: forwards; /* Keeps the final state of the animation */
  opacity: 0; /* Start with opacity 0 to hide the card */
  animation: slidein 0.5s ease-in-out 0.5s forwards; /* Delay the animation for 5 seconds */
}

.card__icon {
  font-size: 100px;
  margin-bottom: 10px;
  color: red;
}

.card__text {
  text-align: center;
}

h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #0056b3;
  text-decoration: underline;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Keyframes animation to make the card slide in */
@keyframes slidein {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .cards {
    display: flex;
    flex-direction: column;
    /* Adjust the styling for the .cards class for smaller screens */
    padding: 5px; /* Example adjustment */
  }

  .cards__information1,
  .cards__information2 {
    /* Remove the 'flex' property to make them stack as blocks */
    margin: 5px;
  }

  .card__icon {
    font-size: 40px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

  