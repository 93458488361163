.contact {
    /* Add background image properties */
    background: url("containerimage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    /*animation: moveBackground 10s linear infinite;*/
    color: white;
    height: 60vh;
  }
 
  .contact__top .Navigation{
     background-color: transparent;
  }
  
  .contact__nav {
    background-color: transparent;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  .contact__nav h2{
    font-size: 40px;
  }

  .contact__information{
    display: flex;
    flex-direction: column;
    margin-top: 150px;
  }
  .contact__information .information__top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact__information .information__top h2{
    color: aqua;
    font-weight: bold;
  }
  .contact__information .information__top h1{
    color: black;
    font-weight: bold;
  }

  .contact__information .information__details{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 50px;
    width: 40%;
  }
  .contact__information .information__details .details__first{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .contact__information .information__details .details__first img{
    width: 120px;
    height: 100px;
  }
  .contact__information .information__details .details__first .details__headers{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 20px;
  }
  .contact__information .information__details .details__first .details__headers h1{
    color: black;
    font-weight: bolder;
    font-size: 24px;
  }
  .contact__information .information__details .details__first .details__headers h3{
    color: gray;
    font-weight: 900;
    font-size: 18px;
    margin-top: 10px;
    text-decoration: none;
  }
  .contact__information .information__details .details__second{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
  }
  .contact__information .information__details .details__second img{
    width: 120px;
    height: 100px;
  }
  .contact__information .information__details .details__second .details__headers2{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-left: 20px;
  }
  .contact__information .information__details .details__second .details__headers2 h1{
    color: black;
    font-weight: bolder;
    font-size: 24px;
  }
  .contact__information .information__details .details__second .details__headers2 h3{
    color: gray;
    font-weight: 900;
    font-size: 18px;
    margin-top: 10px;
    text-decoration: none;
  }
  .contact__information .information__details .details__third{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
  }
  .contact__information .information__details .details__third img{
    width: 120px;
    height: 100px;
  }
  .contact__information .information__details .details__third .details__headers3{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-left: 20px;
  }
  .contact__information .information__details .details__third .details__headers3 h1{
    color: black;
    font-weight: bolder;
    font-size: 24px;
  }
  .contact__information .information__details .details__third .details__headers3 h3{
    color: gray;
    font-weight: 900;
    font-size: 18px;
    margin-top: 10px;
    text-decoration: none;
  }


@media (max-width: 768px) {
  .contact__information{
    display: flex;
    flex-direction: column;
    margin-top: 250px;
  }
  .contact__information .information__top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact__information .information__top h1{
    color: black;
    font-weight: bold;
    margin-left: 20px;
  }
}