
.feedback {
    /* Add background image properties */
    background: url("backcargoimage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    animation: moveBackground 10s linear infinite;
    color: white;
    height: 100vh;
  }
  .feedback__top .Navigation{
     background-color: transparent;
  }
  .feedback__top h1{
   display: flex;
   text-align: center;
   justify-content: center;
   font-size: 25px;
   margin-bottom: 20px;
  }
  /* Style for the container div */
.feedback__input {
   display: flex;
   flex-direction: column;
   max-width: 400px;
   margin: 0 auto;
 }
 
 /* Style for the input containers */
 .name__input,
 .email__input,
 .comments {
   margin-bottom: 20px;
   color: red;
 }
 
 /* Style for the h2 headers */
 h2 {
   font-size: 24px;
   margin-bottom: 5px;
 }
 
 /* Style for the input fields and textarea */
 input[type="text"],
 input[type="email"]{
   width: 100%;
   padding: 10px;
   font-size: 16px;
   border: 1px solid #ccc;
   border-radius: 5px;
 }
 textarea{
   width: 100%;
   padding: 20px;
   font-size: 16px;
   border: 1px solid #ccc;
   border-radius: 5px; 
 }
 
 /* Style for the submit button */
 button[type="submit"] {
   background-color: #007bff;
   color: #fff;
   border: none;
   border-radius: 5px;
   padding: 10px 20px;
   font-size: 16px;
   cursor: pointer;
   width: 400px;
   /*margin-left: 20px;*/
 }
 
 /* Style for the submit button on hover */
 button[type="submit"]:hover {
   background-color: #0056b3;
 }

 
@media (max-width: 768px) {
   button[type="submit"] {
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      width: 100px;
      margin-left: 20px;
    }
   
}