/* Style for the 'body__top' container */
.body{
    background-image: url('backcargoimage.jpg'); /* Replace with your image path */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: moveBackground 10s linear infinite;
    width: 100%;
    margin: 0;
   /* Add padding to create some space around the content */
  }
  
  /* Style for the content within 'body__top' */
  .body__top .Header,
  .body__top .News,
  .body__top .Navigation{
    background-color: rgba(255, 255, 255, 0); /* Add a semi-transparent white background to the content */
    padding: 40px; /* Add padding to the content for better visibility */ /* Add rounded corners to the content */
  }
 
  /* Keyframes for background animation */
@keyframes moveBackground {
    0% {
      background-position: 0% 0%; /* Starting position */
    }
    100% {
      background-position: 100% 100%; /* Ending position */
    }
  }

