
.faq {
    /* Add background image properties */
    background: url("faq3image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    /*animation: moveBackground 10s linear infinite;*/
    color: white;
    height: 60vh;
  }
  .faq__top .Navigation{
     background-color: transparent;
  }
  
  .faq__nav {
    background-color: transparent;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  .faq__nav h2{
    font-size: 40px;
  }

  .faq__body{
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  

@media (max-width: 768px) {
  .faq__body{
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  
}