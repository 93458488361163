.activity {
    /*text-align: center;
    padding: 20px;*/
    /* Add background image properties */
    background: url("containerimage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    /*animation: moveBackground 10s linear infinite;*/
    color: white;
    height: 30vh;
  }
  
  .activity__top .Navigation {
    background-color: transparent;
  }
  .activity__information {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
  }
  .activity__information h2{
    color: black;
    text-decoration: underline;
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .file-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Adjust the margin space between items */
  }
  
  .file-item {
    text-align: left;
    width: 300px; /* Adjust the width of each item as needed */
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .file-item img {
    max-width: 100%;
    height: auto;
  }
  
  .file-item p {
    margin-top: 10px;
    color: black;
  }
  
.p__center{
    text-align: center;
    color: gray;
}
@media (max-width: 768px) {
  .activity__information {
    display: flex;
    flex-direction: column;
    margin-top: 200px;
  }
}