/* Styles for the footer */
footer {
    background-color:transparent; /* Change the background color to your preference */
    color: #fff; /* Change the text color to your preference */
     /* Adjust the padding as needed */
    text-align: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  /* Styles for the container inside the footer */
  .container {
 /* Set the maximum width of the container */
    margin: 0 auto; /* Center the container horizontally */
  }
  
  /* Styles for the copyright text */
  p {
    margin: 0; /* Remove default margin */
    font-size: 16px; /* Set the font size to your preference */
  }
  