.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 10px 20px;
}

/* Default styles for nav-links */
.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.logo img {
  max-width: 150px;
  max-height: 120px;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  text-decoration: none;
  color: white;
}

/* Add styles for the mobile menu */
.mobile-menu-button {
  display: none;
}

.mobile-menu-open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #333;
}

.mobile-menu-open li {
  margin: 10px 0;
}

@media (max-width: 768px) {
  .nav-links{
    display: none;
  }
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .logo img {
    max-width: 100px;
    max-height: 80px;
  }
  .mobile-menu-open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    background-color: black;
    z-index: 1;
  }
  
  .mobile-menu-open li {
    margin: 10px 0;
    background-color: black;
  }
  .mobile-menu-open li:hover{
    margin: 10px 0;
    background-color: #1192f5;
  }

  /* Add styles for the mobile menu */
  .mobile-menu-button {
    display: block;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
  }

}