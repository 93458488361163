.upload {
  /* Add background image properties */
  background: url("containerimage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  /*animation: moveBackground 10s linear infinite;*/
  color: white;
  height: 40vh;
}

.upload__top .Navigation {
  background-color: transparent;
}

.upload__information {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
}

.upload__information .file__upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: red;
}
.file__upload .upload__description{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.file__upload .upload__description h1{
  margin-bottom: 10px;
  font-size: 25px;
  color: black;
  font-weight: 800;
  text-decoration: underline;
}
.file__upload .upload__description textarea{
  padding: 20px;
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
}

.file__upload button {
  width: 300px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .upload__information {
    display: flex;
    flex-direction: column;
    margin-top: 200px;
  }
  .file__upload .upload__description h1{
    margin-bottom: 10px;
    font-size: 25px;
    color: black;
    font-weight: 800;
    text-decoration: underline;
    margin-top: 20px;
  }
  .file__upload .upload__description textarea{
    padding: 10px;
    width: 250px;
    height: 200px;
    margin-bottom: 20px;
  }
 }
