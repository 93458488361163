/* Style for the header container */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    border-bottom: 1px solid #ccc; /* Add a border at the bottom */
    padding-bottom: 10px;
    width: 100%;
  }
  
  /* Style for the left section of the header */
  .header__left {
    display: flex;
    align-items: center;
  }
  
  /* Style for the location icon */
  .location__direction {
    margin-right: 10px;
  }
  
  /* Style for the location text */
  .location__text h3 {
    font-size: 18px;
    margin: 0;
    text-decoration: none;
    color: white;
  }
  
  /* Style for the center section of the header */
.header__center {
    display: flex;
    align-items: center;
    margin-right: 20px; /* Adjust as needed */
  }
  
  /* Style for the phone icon */
  .phone {
    margin-right: 10px;
  }
  
  /* Style for the phone number text */
  .phone_number h3 {
    font-size: 18px;
    text-decoration: none;
    margin: 0;
    color: #fff; /* Text color */
  }
  
  
/* Style for the right section of the header */
.header__right {
    display: flex;
    align-items: center;
  }
  
  /* Style for the social media icons */
  .media__icons a {
    margin-right: 10px; /* Adjust as needed */
    color: #fff; /* Icon color */
    text-decoration: none; /* Remove underlines from links */
    font-size: 24px; /* Adjust as needed */
  }
  
  /* Style for the hover effect on social media icons (optional) */
  .media__icons a:hover {
    color: #0073e6; /* Change the color on hover */
  }
  
  @media (max-width: 768px) {
   .header{
    display: none;
   }
  }