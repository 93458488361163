/* Base styles for screens larger than 768px */
.delivery {
    background-color: #647af5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
  }
  
  .head__information {
    margin-bottom: 20px;
  }
  
  .head__information h3 {
    color: black;
    font-size: 30px;
    text-decoration: underline;
  }
  
  .head__information h1 {
    color: #222;
    font-size: 40px;
    text-decoration: underline;
  }
  
  .cards__display {
    display: flex;
    justify-content: space-between;
  }
  
  .card__one,
  .card__two,
  .card__three {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    flex: 1;
    margin: 0 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .card__one h1,
  .card__two h1,
  .card__three h1 {
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto 10px;
    font-size: 24px;
  }
  
  .card__one h2,
  .card__two h2,
  .card__three h2 {
    color: #333;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .card__one p,
  .card__two p,
  .card__three p {
    color: #666;
    font-size: 16px;
  }
  
  /* Media query for screens less than 768px wide */
  @media (max-width: 768px) {
    .delivery {
      padding: 10px;
      margin-top: 10px;
    }
    .cards__display {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    .head__information h3 {
      font-size: 24px;
    }
  
    .head__information h1 {
      font-size: 32px;
    }
  
    .card__one,
    .card__two,
    .card__three {
      flex: 1;
      margin: 10px 5px;
      padding: 10px;
    }
  
    .card__one h1,
    .card__two h1,
    .card__three h1 {
      font-size: 20px;
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  
    .card__one h2,
    .card__two h2,
    .card__three h2 {
      font-size: 18px;
    }
  
    .card__one p,
    .card__two p,
    .card__three p {
      font-size: 14px;
    }
  }
  