.News {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    padding: 10px 10px;
  }
  
  .news__information {
    display: flex;
    flex-direction: row; /* Display both left and moving in a row */
    align-items: center;
    background-color: white;
  }
  
  .news__left {
    display: flex;
    align-items: center;
    background-color: green;
    color: #fff;
    padding: 20px 20px;
    margin-right: 20px; /* Add spacing between left and moving */
  }
  
  .news__left h3 {
    margin-left: 10px;
    color: #fff;
    text-decoration: none;
  }
  
  .news__moving {
    display: flex;
    padding: 20px 20px;
    flex-direction: row; /* Display links in a row */
    animation: moveRightToLeft 10s linear infinite; /* Animation for infinite movement */
    overflow: hidden; /* Hide any overflow content */
    position: relative; /* Position for constraint */
  }
  
  .news__moving a {
    text-decoration: none;
    margin-right: 20px; /* Add spacing between links */
    color: blue;
  }
  
  /* Keyframes for infinite movement */
  @keyframes moveRightToLeft {
    0% {
      transform: translateX(10%);
    }
    100% {
      transform: translateX(-7%); /* Move only the 'news__moving' element within the constraints */
    }
  }

/* Style for the 'news__under' div */
.news__under {
    display: flex;
    flex-direction: column;
    text-align: center; /* Center-align text horizontally */
    margin-top: 20px; /* Add spacing from the content above */
  }
  
  .news__under h3 {
    font-size: 24px;
    margin: 0; /* Remove default margin */
    color: white; /* Text color for 'news__under' */
  }
  
  @media (max-width: 768px) {
    .News {
      display: none; /* Maintain row direction for elements */
      /*padding: 20px;*/
    }
  
    .news__left {
      margin-right: 20px;
    }
  
    .news__moving {
      flex-direction: row; /* Maintain row direction for links */
    }
  
    .news__moving a {
      margin-right: 20px;
    }
  }