/* Style for the header container */
.header__above {
    display: flex;
    margin-top: 100px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    border-bottom: 1px solid #ccc; /* Add a border at the bottom */
    padding-bottom: 10px;
  }
  
  /* Style for the left section of the header */
  .above__left {
    display: flex;
    align-items: center;
  }
  
  /* Style for the location icon */
  .above__direction {
    margin-right: 10px;
  }
  
  /* Style for the location text */
  .above__text img {
    max-width: 150px;
    margin-left: 20px;
  }
  
  /* Style for the center section of the header */
.above__center {
    display: flex;
    align-items: center;
    margin-right: 20px; /* Adjust as needed */
  }
  
  /* Style for the phone icon */
  .above__phone {
    margin-right: 10px;
  }
  
  /* Style for the phone number text */
  .above__number h3 {
    font-size: 18px;
    text-decoration: none;
    margin: 0;
    color: #fff; /* Text color */
  }
  
  
/* Style for the right section of the header */
.above__right {
    display: flex;
    align-items: center;
  }
  
  /* Style for the social media icons */
  .above__icons a {
    margin-right: 10px; /* Adjust as needed */
    color: #fff; /* Icon color */
    text-decoration: none; /* Remove underlines from links */
    font-size: 24px; /* Adjust as needed */
  }
  
  /* Style for the hover effect on social media icons (optional) */
  .above__icons a:hover {
    color: #0073e6; /* Change the color on hover */
  }
  
  @media (max-width: 768px) {
    .header__above {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      color: #fff;
      border-bottom: 1px solid #ccc; /* Add a border at the bottom */
      padding-bottom: 10px;
    }
    .above__center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0px;
      margin-top: 20px;
      margin-right: 20px; /* Adjust as needed */
    }
    .above__left {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    /* Style for the location icon */
    .above__direction {
      margin-right: 10px;
    }
    .above__direction .icon__location{
      display: none;
    }
    .above__text img {
      max-width: 200px;
      height: 100px;
      margin-left: 0px;
    }
    .above__right {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
    .above__phone {
      display: none;
    }

    .above__number h3 {
      font-size: 16px;
      text-decoration: none;
      margin: 0;
      color: #fff; /* Text color */
    }
  }