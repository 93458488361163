/* UploadInput.css */
.input__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the container to the full viewport height */
  }
  
  .input__details {
    display: flex;
    flex-direction: column;
    text-align: center; /* Center the content inside the div */
  }
  .input__details h1{
    color: #007bff;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  input[type='text'] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  
  button[type='submit'] {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  button[type='submit']:hover {
    background-color: #0056b3;
  }
  