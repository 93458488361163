
.services {
    /* Add background image properties */
    background: url("backcargoimage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    animation: moveBackground 10s linear infinite;
    color: white;
    height: 60vh;
  }
  .service__top .Navigation{
     background-color: transparent;
  }
  
  .service__nav {
    background-color: transparent;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .service__information{
    margin-top: 200px;
    display: flex;
  }
  .service__information .service__left{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 40%;
  }
  .service__information .service__left .information__header{
    background-color: #d6d2d2;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    border-left: solid 6px blue;
  }
  .service__information .service__left .information__header h3{
    font-size: 30px;
    margin-bottom: 10px;
    color: black;
  }

  .service__information .service__left .information__header p{
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #000;
  }

  .service__information .service__right{
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 50%;
  }

  .service__information .service__right .information__header{
    background-color: #d6d2d2;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    border-left: solid 6px blue;
  }
  .service__information .service__right .information__header h3{
    font-size: 30px;
    margin-bottom: 10px;
    color: black;
  }
  .service__information .service__right .information__header p{
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #000;
  }

  .service__f{
    background-color: #333;
  }

  @media (max-width: 768px) {
    .service__nav {
        background-color: transparent;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
      }
    .service__nav h2{
        font-size: 20px;
    }
    .service__information{
        margin-top: 200px;
        display: flex;
        flex-direction: column;
      }
      .service__information .service__left{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        width: 80%;
      }
      .service__information .service__right{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        width: 80%;
      }
    
  }