/* About.css */

.about {
  /* Add background image properties */
  background: url("backcargoimage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  animation: moveBackground 10s linear infinite;
  color: white;
  height: 60vh;
}
.about__top .Navigation{
   background-color: transparent;
}

.about__nav {
  background-color: transparent;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

/* Set a fixed width for the video */
video {
  width: 400px;
  height: 200px;
  max-width: 400px; /* Adjust this value as needed */
  display: block;
  margin: 0 auto; /* Center the video horizontally */
}

/* Style the video controls */
video::-webkit-media-controls {
   /* Semi-transparent black background */
  color: white; /* Text color */
  border-radius: 10px;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pause-button {
  font-size: 24px; /* Size of play and pause buttons */
}

/* Style the progress bar */
video::-webkit-media-controls-progress-bar {
  background-color: #333; /* Background color of the progress bar */
}

video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display {
  color: white; /* Text color for time displays */
}

video::-webkit-media-controls-volume-slider {
  width: 100px; /* Width of the volume slider */
}

video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-volume-slider {
  color: white; /* Text and slider color for volume controls */
}


.about__center{
  margin-top: 200px;
  display: flex;
}
.about__center .image__video img{
  margin-top: 20px;
  margin-left: 20px;
  width: 300px;
  height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.about__center .about__information{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  width: 60%;
}
.about__center .about__information h1{
  color: black;
  margin-bottom: 20px;
  margin-top: 20px;
  text-decoration: none;
}

.about__center .about__information p{
  color: black;
  font-size: 18px;
  text-indent: 1rem;
  text-align: center;
  line-height: 1.5;
}

/* Style for the information navigation */
.information__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.information__nav a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  margin-bottom: 10px;
  transition: color 0.2s ease;
}

.information__nav a:hover {
  color: #0056b3;
}

/* Style for the icon container */
.icon-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.description {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.description__paragraph {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

/*mission__vission css*/
/* Style for the parent container */
.about__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

/* Style for the mission and vision sections */
.mission__vission {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
}

.mission,
.vission {
  background-color: #d6d2d2;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  border-left: solid 6px black;
}

.mission__h2,
.vission__h2 {
  font-size: 30px;
  margin-bottom: 10px;
  color: blue;
}

.mission__p,
.vission__p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #000;
}

/* Style for the core value section */
.core__value {
  flex: 1;
  padding: 10px;
  background-color: white;
}

.core__h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: blue;
}

.core__p {
  font-size: 20px;
  line-height: 1.5;
  /*margin-bottom: 10px;*/
  color: gray;
}

.about__f{
  background-color: #333;
}

@media (max-width: 768px) {
  .about__center{
    margin-top: 200px;
    display: flex;
    flex-direction: column;
  }
  .about__center .image__video img{
    margin-top: 10px;
    margin-left: 20px;
    width: 200px;
    height: 150px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  video {
    width: 300px;
    height: 100px;
    max-width: 300px; /* Adjust this value as needed */
    display: block;
    margin: 15px 5px; /* Center the video horizontally */
  }
  .about__center .about__information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    margin-left: 40px;
    /*width: 60%;*/
  }
  .about__center .about__information h1{
    color: black;
    margin-bottom: 20px;
    margin-top: 20px;
    text-decoration: none;
  }
  .about__center .about__information p{
    color: black;
    font-size: 14px;
    text-indent: 1rem;
    text-align: center;
    line-height: 1.5;
  }

  .about__bottom{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

}