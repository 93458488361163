.advert__image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.advert__image .top__image{
    display: flex;
    flex-direction: row;
}
.advert__image .bottom__image{
    display: flex;
    flex-direction: row;
}

.advert__image .first__image{
    height: 150;
    width: 150;
    margin-left: 10px;
}
.advert__image .second__image{
    height: 150;
    width: 150;
    margin-left: 10px;
}
.advert__image .third__image{
    height: 150;
    width: 150;
    margin-left: 10px;
}
.advert__image .fourth__image{
    height: 150;
    width: 150;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .advert__image{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
    .advert__image .top__image{
        display: flex;
        flex-direction: column;
    }
    .advert__image .bottom__image{
        display: flex;
        flex-direction: column;
    }
    .advert__image .first__image{
        height: 430px;
        width: 430px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .advert__image .second__image{
        height: 430px;
        width: 430px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .advert__image .third__image{
        height: 430px;
        width: 430px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .advert__image .fourth__image{
        height: 430px;
        width: 430px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
}