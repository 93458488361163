/* Style for the 'news__under' container */
.news__under {
    display: flex;
    padding: 10px 20px;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically *//* Ensure the container takes up the full height of its parent */
  }
  
  /* Style for the 'news__text' container (optional) */
  .news__text {
    text-align: center; /* Center-align text horizontally */
  }
  
  /* Style for the 'h3' elements */
  .news__under h3 {
    font-size: 30px;
    text-decoration: none;
    margin: 0; /* Remove default margin */
    color:  white; /* Text color for 'news__under' */
  }
  
  @media (max-width: 768px) {
    .news__text {
        text-align: center; /* Center-align text horizontally */
      }
      
      /* Style for the 'h3' elements */
      .news__under h3 {
        font-size: 20px;
        margin: 0; /* Remove default margin */
        color:  white; /* Text color for 'news__under' */
      }
  }