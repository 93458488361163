/* Testimonial.css */

.testimonial {
    background-color: #f5591b;
    padding: 40px;
    text-align: center;
    margin-top: 40px;
  }
  
  .testimonial__header {
    margin-bottom: 30px;
  }
  
  .testimonial__header h3 {
    color: white;
    font-size: 24px;
  }
  
  .testimonial__header h1 {
    color: white;
    font-size: 36px;
  }
  
  .testimonial__information {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .testimonial__info-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1;
    margin: 0 10px;
    opacity: 0; /* Start with opacity 0 to hide the elements */
    transform: translateX(100%); /* Start on the right */
  }
  
@media (max-width: 768px) {
    .testimonial__information {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }
    
      .testimonial__info-item {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        flex: 1;
        margin: 0 10px;
        opacity: 0; /* Start with opacity 0 to hide the elements */
        transform: translateX(100%); /* Start on the right */
      }
}