/* UnderCard.css */

.undercard {
    background-color: transparent;
    /*padding: 20px;*/
    border-radius: 10px;
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .first__text h2 {
    font-size: 60px;
    text-align: start;
    color: orange;
  }
  
  .second__text h3 {
    font-size: 40px;
    color: white;
    margin-top: 30px;
    text-decoration: none;
  }
  
  .third__card p {
    font-size: 30px;
    color: white;
    text-decoration: none;
    background-color: grey;
    line-height: 1.5;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center; /* Center-align the text within the <p> element */
  }
  
  @media (max-width: 768px) {
    .first__text h2 {
        font-size: 40px;
        text-align: center;
        color: orange;
      }
  }
  